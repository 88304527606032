import styled from '@emotion/styled';

export const Footer = styled.footer`
    height: 12.5rem;
    background: #222222;

    // center contents horizontally and vertically
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FooterText = styled.div`
    color: #dfdfdf;
`;
