import 'bootstrap/dist/css/bootstrap.min.css';
import './app.styl';

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// Components
import Header from 'components/Header';
import SiteFooter from 'components/Footer';

const Layout = ({ children }) => (
    <div className="page-content">
        <Helmet
            htmlAttributes={{ lang: 'en' }}
            title="abrad45.com"
            meta={[
                {
                    name: 'description',
                    content: `Alex Bradley's home on the internet`,
                },
            ]}
        />
        <Header />
        <div className="container justify-content-center">
            <div className="row">
                <div className="col-sm-12 col-xl-10 self-align-center">
                    {children}
                </div>
            </div>
        </div>
        <SiteFooter />
    </div>
);

Layout.propTypes = {
    children: PropTypes.shape({}),
};

export default Layout;
