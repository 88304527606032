import React from 'react';
import { Link } from 'gatsby';

// Components
import Tagline from './Tagline';
import { Header, HeaderText, HeaderLinkText } from './components';
import { ThemeProvider } from 'emotion-theming';

const weightedRandomRupee = (val = Math.random()) => {
    let maxRupee = 0;
    const rupeeValues = {
        gold: 300,
        silver: 100,
        purple: 50,
        red: 20,
        // yellow: 10,      // too blinding
        blue: 5,
        green: 1,
    };

    for (const key in rupeeValues) {
        maxRupee += 2 / rupeeValues[key];
        if (val < maxRupee) {
            return key;
        }
    }
};

class SiteHeader extends React.Component {
    constructor() {
        super();

        this.state = {
            headerColor: '',
        };
    }

    componentDidMount() {
        this.setState({
            headerColor: weightedRandomRupee(),
        });
    }

    render() {
        const { headerColor } = this.state;
        const theme = {
            main: headerColor === 'silver' ? 'dark' : 'light',
        };

        return (
            <Header
                className="container-fluid header mb-5"
                color={this.state.headerColor}
            >
                <ThemeProvider theme={theme}>
                    <HeaderText className="container header-text text-center">
                        <Link to="/" className="site-name">
                            <HeaderLinkText>abrad45.com</HeaderLinkText>
                        </Link>
                        <Tagline />
                    </HeaderText>
                </ThemeProvider>
            </Header>
        );
    }
}

export default SiteHeader;
