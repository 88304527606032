import React from 'react';

const taglines = [
    'make paper mario great again',
    'plow and sow is OP',
    'tier, pargon pargon pargon...',
    'four space soft tabs',
    'you crumb bum',
    'nerdery abounds',
    'grumo',
    `don't get cooked, stay off the hook`,
    'there are eight bears',
    'odyssey, ya see',
    'estd. 1988',
    'you want philly philly?',
    `it's dangerous to go alone...`,
    'this is soup for my family',
    'fly, eagles, fly',
    'curse you, mother and child isles',
    'rip airpower: 2017-2019',
    'switch fc: sw-8509-1469-8290',
    'grump',
    'i miss asante samuel',
    'just do it',
    'hungry dogs run faster',
];

const getTagline = () => taglines[Math.floor(Math.random() * taglines.length)];

class Tagline extends React.Component {
    constructor() {
        super();

        this.state = {
            tagline: '',
        };
    }

    componentDidMount() {
        this.setState({
            tagline: getTagline(),
        });
    }

    render() {
        return (
            <span className="tag-name">
                <em>{this.state.tagline}</em>
            </span>
        );
    }
}

export default Tagline;
