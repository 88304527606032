import React from 'react';
import { Link } from 'gatsby';

// Components
import { Footer, FooterText } from './components';

const SiteFooter = () => (
    <Footer className="container-fluid footer mt-5">
        <FooterText className="footer-text">
            abrad45.com is where I write whatever I want. Learn more on the{' '}
            <Link to="/colophon">colophon</Link>.
        </FooterText>
    </Footer>
);
export default SiteFooter;
